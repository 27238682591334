import React from 'react';
import type { FC } from 'react';
import { Link, graphql } from 'gatsby';
import { Layout } from '../components/layout';
// eslint-disable-next-line import/no-unresolved
import { ResearchAchievementPageQuery } from 'graphql-types';
import { SEO } from '../components/seo';

interface PageProps {
  data: ResearchAchievementPageQuery;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO
      title="学術実績"
      description="私たちは犬と猫の腫瘍に関する学会発表や研究も真摯に取り組んでおります。"
      keywords="学術実績"
    />
    <article id="clinical-examination">
      <h2>学術実績 - Research achievement -</h2>
      <div className="skew-border"></div>
      <section>
        <h3>学会口頭発表</h3>
        <ul>
          <li>
            2010年&nbsp;鉄欠乏性貧血を起こした小腸腺癌の犬の１例&nbsp;動物臨床医学会年次大会
          </li>
          <li>
            2011年&nbsp;外科手術を行った原発性上皮小体機能亢進症の犬の１例&nbsp;日本臨床獣医学フォーラム
          </li>
          <li>
            2013年&nbsp;犬の脾臓腫瘤癌３１症例の回顧的研究（2007～2013年）&nbsp;日本獣医がん学会
          </li>
          <li>
            2015年&nbsp;３歳齢で pseudo-Meigs
            症候群を呈した卵巣腺癌の犬の１例日本獣医麻酔外科学会
          </li>
          <li>
            2015年&nbsp;外科切除にて長期間生存した高悪性度Ｔ細胞性消化器型リンパ腫の犬の１例&nbsp;日本獣医がん学会
          </li>
          <li>
            2016年&nbsp;眼内腫瘤を主訴に来院した播種性組織球肉腫の犬の１例&nbsp;日本獣医がん学会
          </li>
          <li>
            2017年&nbsp;犬の高悪性度消化器型リンパ腫１０症例の回顧的&nbsp;日本獣医がん学会
          </li>
          <li>
            2018年&nbsp;腰椎に発生を認めた全身性肥満細胞症の猫の１例&nbsp;日本獣医がん学会
          </li>
          <li>
            2019年&nbsp;不完全切除の線維肉腫に対し拡大切除を実施した犬の１例&nbsp;日本獣医がん学会
          </li>
          <li>
            2021年&nbsp;Modified Dorsolateral and Intraoral
            Approachを実施した尾側上顎骨アミロイド産生性歯原性腫瘍の犬の1例&nbsp;日本獣医麻酔外科学会
          </li>
          <li>
            2021年&nbsp;心基底部腫瘍に起因した二次性乳び胸の犬の１例&nbsp;動物臨床医学会
          </li>
          <li>
            2022年&nbsp;術後にオーダーメイド化学療法を実施した前立腺癌の犬の1例&nbsp;日本獣医がん学会
          </li>
          <li>
            2022年&nbsp;排便困難に対する緩和的リンパ節切除を実施した犬の1例&nbsp;日本獣医がん学会
          </li>
          <li>
            2023年&nbsp;肝芽腫の犬の1例&nbsp;日本獣医がん学会
          </li>
          <li>
            2025年&nbsp;猫の大細胞性消化器型リンパ腫に対するL-アスパラギナーゼ連続投与の有用性の検討
          </li>
        </ul>
      </section>
      <section>
        <h3>著書</h3>
        <ul>
          <li>
            2017年&nbsp;犬の高悪性度消化器型リンパ腫１０症例の回顧的&nbsp;ＪＯＮＣＯＬNo,24
          </li>
          <li>
            2019年&nbsp;腰椎に発生を認めた全身性肥満細胞症の猫の１例&nbsp;ＪＯＮＣＯＬNo,27
          </li>
          <li>
            2020年&nbsp;不完全切除の線維肉腫に対し拡大切除を実施した犬の1例&nbsp;ＪＯＮＣＯＬNo,28
          </li>
          <li>
            2022年&nbsp;術後にオーダーメイド化学療法を実施した前立腺癌の犬の1例&nbsp;ＪＯＮＣＯＬNo,32
          </li>
          <li>
            2023年&nbsp;排便困難に対する緩和的リンパ節切除を実施した犬の1例&nbsp;ＪＯＮＣＯＬNo,33
          </li>
          <li>
            2023年&nbsp;施設間連携&nbsp;〜どうしてる？&nbsp;症例の紹介〜ＪＯＮＣＯＬNo,34
          </li>
        </ul>
      </section>
      <section>
        <h3>講演</h3>
        <ul>
          <li>What is your diagnosis? 脾臓の多発性結節　VETS CHANNEL</li>
          <li>What is your diagnosis? 犬の大腿部皮下腫瘤　VETS CHANNEL</li>
          <li>
            what is your diagnosis? 犬の体表リンパ節腫大　診断　VETS CHANNEL
          </li>
          <li>
            what is your diagnosis? 犬の体表リンパ節腫大　治療　VETS CHANNEL
          </li>
          <li>2022年　日本獣医がん学会　総合教育口演　治療学総論</li>
          <li>2024年　東京都獣医師会　城北支部　腫瘍セミナー緩和ケア</li>
          <li>2024年　日本獣医がん学会　総合教育口演　治療学総論</li>
          <li>2024年　第26回JBVP年次大会　愛玩動物看護師の知っておくべき抗がん剤の基礎知識</li>
          <li>2025年　日本獣医がん学会　猫の肥満細胞腫</li>
        </ul>
      </section>
    </article>
  </Layout>
);
export default Page;

export const query = graphql`
  query ResearchAchievementPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
